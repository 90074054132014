<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Please fill in the blanks using the dropdown menus below.</p>

      <p class="mb-2">
        A stock solution is a solution
        <v-select
          v-model="inputs.input1"
          class="ml-3"
          style="display: inline-block; max-width: 250px"
          :items="options1"
          item-text="text"
          item-value="value"
          label="A"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        A standard solution is a solution
        <v-select
          v-model="inputs.input2"
          class="ml-3"
          style="display: inline-block; max-width: 250px"
          :items="options1"
          item-text="text"
          item-value="value"
          label="B"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        When performing a dilution, the moles of the stock solution in the volumetric
        <v-select
          v-model="inputs.input3"
          class="ml-3 mb-0"
          style="display: inline-block; max-width: 150px"
          :items="options2"
          item-text="text"
          item-value="value"
          label="C"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        equals the moles of the dilute solution
        <br />
        in the volumetric
        <v-select
          v-model="inputs.input4"
          class="ml-3 mt-0"
          style="display: inline-block; max-width: 150px"
          :items="options2"
          item-text="text"
          item-value="value"
          label="D"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S1Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options1: [
        {text: 'Made with a solute & an exact volume solvent', value: 'solute'},
        {text: 'Created in a beaker', value: 'inBeaker'},
        {text: 'With an accurately known high concentration', value: 'conc'},
        {
          text: 'Made by diluting an original solution by a specific volume of solvent',
          value: 'solvent',
        },
        {text: 'Two of these', value: 'two'},
        {text: 'All of these', value: 'all'},
      ],
      options2: [
        {text: 'Beaker', value: 'beaker'},
        {text: 'Pipet', value: 'pipet'},
        {text: 'Graduated cylinder', value: 'grad'},
        {text: 'Flask', value: 'flask'},
      ],
    };
  },
};
</script>
<style scoped></style>
