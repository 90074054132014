var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v("Please fill in the blanks using the dropdown menus below.")]),_c('p',{staticClass:"mb-2"},[_vm._v(" A stock solution is a solution "),_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.options1,"item-text":"text","item-value":"value","label":"A"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" A standard solution is a solution "),_c('v-select',{staticClass:"ml-3",staticStyle:{"display":"inline-block","max-width":"250px"},attrs:{"items":_vm.options1,"item-text":"text","item-value":"value","label":"B"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',[_vm._v(" When performing a dilution, the moles of the stock solution in the volumetric "),_c('v-select',{staticClass:"ml-3 mb-0",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options2,"item-text":"text","item-value":"value","label":"C"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" equals the moles of the dilute solution "),_c('br'),_vm._v(" in the volumetric "),_c('v-select',{staticClass:"ml-3 mt-0",staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.options2,"item-text":"text","item-value":"value","label":"D"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }